.product-filter {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
	color: #25262a;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	margin-bottom: 20px;
}

.product-filter .limit,
.product-filter .sort,
.product-size {
	position: relative;
}

.product-filter .limit {
	margin-right: 20px;
}

.product-filter select,
.product-size select,
.product-size select {
	height: 32px;
	border: 1px solid #e5e5e5;
	margin-left: 10px;
	padding-left: 10px;
	padding-right: 30px;
	appearance: none;
}

.product-filter .limit::after,
.product-filter .sort::after,
.product-size::after {
	position: absolute;
	top: 3px;
	right: 1px;
	width: 30px;
	height: 26px;
	background-color: #FFF;
	background: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAQCAwMDAgQDAwMEBAQEBQkGBQUFBQsICAYJDQsNDQ0LDAwOEBQRDg8TDwwMEhgSExUWFxcXDhEZGxkWGhQWFxb/2wBDAQQEBAUFBQoGBgoWDwwPFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhYWFhb/wgARCAAJAA8DAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAgUGCP/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAANNhl8OT/8QAKBAAAAQCCQUBAAAAAAAAAAAAAQIDBQQGAAcREhRBUnSyExU1N5Hx/9oACAEBAAE/AIqNdqmH9VVxXjHSQ3NcT9c15VdlVNqzMkI/OUswT3Wy+kmp7xzTKMNe7M2kVFJWOtAS4lazLSH6NbPrV62ZqMnhoTbk40//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AB//2Q==') center center no-repeat;
	content: "";
	pointer-events: none;
	border-left: 1px solid #e5e5e5;
}

.product-size::after {
	right: 6px;
}
.top-menu {
	background-image: linear-gradient(to top, #a0d44b 0%, #8ad05a 100%);
	border: 1px solid #8dba3f;
}

.top-menu__ul {
	padding-left: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 15px 0;
	margin-bottom: 0;
}

.top-menu__ul li {
	flex-grow: 1;
}

.top-menu__ul li:not(:first-child):not(:last-child) {
	text-align: center;
}

.top-menu__ul li:last-child {
	text-align: right;
}

.top-menu__ul a {
	text-decoration: none;
	color: white;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 600;
}

.top-menu__ul li:hover a {
	text-decoration: underline;
}

.menu-trigger { display: none; }





@media (width >= 1200px) {
	.top-menu__ul li:not(:first-child):not(:last-child) {
		flex-grow: 1;
		text-align: center;
	}

	.top-menu__ul li:not(:first-child) {
		border-left: 1px solid white;
	}

	.top-menu__ul li:first-child {
		padding-right: 20px;
		flex-grow: 0;
	}

	.top-menu__ul li:last-child {
		padding-left: 20px;
		flex-grow: 0;
	}
}





@media (width <= 1199px) {
	.top-menu__ul a {
		font-size: 15px;
	}
}





@media (width <= 991px) {
	.menu-trigger {
		display: block;
		text-align: center;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
		font-weight: 700;
		font-size: 18px;
		cursor: pointer;
		padding: 10px;
	}

	.menu-trigger:hover {
		text-decoration: underline;
	}

	.top-menu__ul {flex-wrap: wrap;
		justify-content: space-around;
		opacity: 0;
		height: 0;
		transition: all .3s;
		margin: 0;
		padding: 0;
	}

	.top-menu__ul.is-visible {
		opacity: 1;
		height: auto;
		transition: all .3s;	
	}

	.top-menu__ul li {
		display: none;
		flex-basis: 30%;
		text-align: center;
		margin: 5px;
	}

	.top-menu__ul.is-visible li { display: block; }
	.top-menu__ul li:last-child { text-align: center; }

}





@media (width <= 479px) {

	.top-menu__ul li {
		display: none;
		flex-basis: 100%;
		text-align: center;
		margin: 5px;
	}
	
}
.main-block {
	margin-bottom: 40px;
}

.main-block-heading,
h1 {
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 15px;
}

.main-block p {
	color: #6c6a6a;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
}

.main-block  h2 {
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.main-block ul {
	list-style: none;
}

.main-block li {
	color: #6c6a6a;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 15px;
	position: relative;
}

.main-block li::before {
	position: absolute;
	top: 3px;
	left: -25px;
	content: "";
	width: 18px;
	height: 16px;
	background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAMCAgMCAgMDAgMDAwMDBAcFBAQEBAkGBwUHCgkLCwoJCgoMDREODAwQDAoKDhQPEBESExMTCw4UFhQSFhESExL/2wBDAQMDAwQEBAgFBQgSDAoMEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhL/wgARCAAQABIDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAABQYHCf/EABgBAAMBAQAAAAAAAAAAAAAAAAQFBgcI/9oADAMBAAIQAxAAAADS6APBxhr7tqRQyxoHkzKX0JPf/8QAJhAAAQIFAgYDAAAAAAAAAAAAAQIDAAQFBhESQQcQExQhUSIyYf/aAAgBAQABPwCs3pI0Vb7D4d7ltGUILZwvx4wYs++2ESfQrj6+4Lp0KDal6wo/g9wlepII3G4jiFT36oxIy1Pleu+t0nWB9EgbnYHMW5aU5Q7hll1KWbfYUCEOp+SW14yD7ByMZPL/xAAoEQABAgQFAgcAAAAAAAAAAAABAxECBAUGAAcSIVETcRAUMTJBYcH/2gAIAQIBAT8AuHMGl0ZRSXU1dYDYaSx434+8WLmRKiU8tVFD1NRYsS4PYc4giEUILYzSpc1U0JaVk0OooYvVvaG5+AfzFqWRUKJcKCk+kI0yNohuIYmcduHI8P/EAB8RAAECBgMAAAAAAAAAAAAAAAIDBAABBRAREhMiMf/aAAgBAwEBPwBvTlVsEPkP6aW2yI9bUpUUiIiLEoePk125cc8Tt//Z');
}





@media screen and (max-width: 991px) {
	.main-block {
		padding: 15px;
	}
}
.action-block {
	background-image: linear-gradient(to top, #a0d44b 0%, #8ad05a 100%);
}

.action-block-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 5px 0;
}
.action-block--left {
	flex-basis: 32%;
	width: 32%;
}

.action-block--left p:first-child {
	font-family: "Open Sans";
	font-weight: 700;
	color: #f4f4f6;
	font-size: 16px;
	margin-bottom: 3px;
}

.action-block--left a {
	color: #5c9930;
	font-size: 14px;
	font-weight: 700;
	margin-left: 15px;
	position: relative;
}

.action-block--left a::after {
	position: absolute;
	display: block;
	content: "";
	top: 5px;
	right: -10px;
	width: 6px;
	height: 11px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALCAMAAABrjAykAAAANlBMVEUAAABcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTBcmTCfGCc2AAAAEXRSTlMAsodo85iMTdWhk35xbiAQC3UL49EAAAA0SURBVAjXHcm3AQAgDANBTDA5aP9lsaXir1DQGHyKRAs2FVTnSqYnNydisdOaUNjhR5dn/SlPASLFyWnDAAAAAElFTkSuQmCC');
}

.action-block--left p:last-child {
	color: #f4f4f6;
	font-size: 23px;
	font-family: "Open Sans";
	font-weight: 700;
	margin-bottom: 0;
}

.action-block--middle {
	display: flex;
	justify-content: space-between;	
	align-items: flex-end;
	position: relative;
	flex-basis: 420px;
	width: 420px;
}

.action-block--middle > span:nth-child(1) {
	font-family: "Open Sans";
	font-weight: 700;
	color: #fffe99;
	font-size: 23px;
}

.action-block--middle > span:nth-child(2) {
	position: absolute;
	color: #fffe99;
	font-family: "Open Sans";
	font-size: 110px;
	font-weight: 700;
	-webkit-text-stroke: 2px #92d155;
	text-shadow: 1.5px 2.6px 7px rgba(0, 0, 0, 0.14);
	top: calc(50% - 82px);
	left: calc(50% - 99px);
}

.action-block--middle > span:nth-child(3) span:first-child {
	font-family: "Open Sans";
	font-weight: 700;
	color: #fffe99;
	font-size: 23px;
	display: block;
}

.action-block--middle > span:nth-child(3) span:last-child {
	font-family: "Open Sans";
	font-weight: 700;
	color: #f4f4f6;
	font-size: 18px;
}

.action-block--right {
	flex-basis: 300px;
	width: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.action-block--right form {
	position: relative;
}

.action-block--right input:first-child {
	background-color: #f4f4f6;
	border-radius: 2px;
	border: 1px solid white;
	width: 100%;
	height: 34px;
	color: #9a9090;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	text-transform: uppercase;
	padding-left: 15px;
}

.action-block--right input:last-child {
	position: absolute;
	top: 3px;
	right: 3px;
	background-color: rgba(146, 209, 87, 0.51);
	border-radius: 2px;
	border: none;
	height: 28px;
}





@media screen and (max-width: 1199px) {
	
	.action-block--left {
		flex-basis: 35%;
		width: 35%;
	}

	.action-block--left p:last-child {
		font-size: 20px;
	}

	.action-block--middle {
		flex-basis: 350px;
		width: 350px;
	}

	.action-block--middle > span:nth-child(1) {
		font-size: 20px;
	}

	.action-block--middle > span:nth-child(2) {
		font-size: 95px;
		top: calc(50% - 71px);
		left: calc(50% - 97px);		
	}

	.action-block--middle > span:nth-child(3) span:first-child {
		font-size: 20px;
	}

	.action-block--right {
		flex-basis: 270px;
		width: 270px;
	}
}





@media screen and (max-width: 991px) {
	.action-block--left,
	.action-block--middle,
	.action-block--right {
		flex-basis: 100%;
		width: 100%;
		max-width: 500px;
		margin: 0 auto 10px;
	}

	.action-block--left {
		text-align: center;
	}

	.action-block--middle {
		justify-content: center;
		align-items: center;
	}

	.action-block--middle > span:nth-child(2) {
		position: static;
		margin-right: 15px;
	}

	.action-block--right form {
		max-width: 390px;
		margin: 0 auto;
		width: 100%;
	}
}





@media screen and (max-width: 479px) {

	.action-block-wrapper {
		padding: 15px;
	}
	.action-block--middle > span:nth-child(2) {
		font-size: 60px;
		margin: 0;
	}

	.action-block--middle > span:nth-child(1),
	.action-block--middle > span:nth-child(3) span:first-child,
	.action-block--middle > span:nth-child(3) span:last-child {
		font-size: 15px;
	}


}
.block-heading--single {
	color: #8bd059;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 700;
	line-height: 13px;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 30px;
}

.profit-block-wrapper {
	background-image: linear-gradient(to top, #a0d44b 0%, #8ad05a 100%);
	margin-bottom: 40px;
}

.profit-block-stripe {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.profit-block-item-wrapper {
	flex-basis: 22%;
	width: 22%;
	padding-left: 80px;
	padding-right: 50px;
	border-left: 1px solid white;
	flex-grow: 1;
}

.profit-block-item-wrapper:last-child {
	border-right: 1px solid white;
}

.profit-block-item { margin: 15px 0 15px 15px; }

.profit-block-item span {
	color: black;
	font-family: "Open Sans";
	font-size: 14px;
	line-height: 13px;
	font-weight: 400;
	position: relative;
}


.profit-block-item span::before {
	position: absolute;
	content: "";
	top: -7px;
	left: -60px;
	width: 50px;
	height: 50px;
}

.profit-block-item-wrapper:nth-child(1) span::before {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAApVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+4/eNVAAAANnRSTlMAoPvtHBf4KfIT6bIiCwS43zTSlWr1eNrMwZmQiGFcTUM7MBAH5LyCfVdTDceqpGSsp51wSA9YYCQaAAACO0lEQVRIx+2U15LiMBBFjRPO2QYnnHAiDAzB//9pK8kMEoO1+Hlr7wPVqPrI6it1M/9Fit+1rttl/GxAThtlGAZWUfv9PEJIuOGhOrjPIfROgtksC385z5iB5CuQKjVxHEJ0Xc74SCICIl7uq3IBGPY6o/Yz3NuG4c0Bobv97K8J8s4CDDUYflXzkO8RUWEof7YYbS0jWoXh/UN+vvNgAeFp1/c7bz2GuUAnyq+a40SQJ3JAz9C6LGnENmCHSUkeDdm7w7TYRP/JMfTXK7nQkPjH6aXXpRrp7zcNiR4G2EdOVCKC0VQasuBHQyNYLNdjZGkOFB01VIeHusLfEB6HNERFLhcO+nOwMWKvXxNF53xZmBY4TFPiUhUPmwZ3IeV0tqzvhdxV0FeMzkf7uMJrc2Gxh+zRkJWnnsDGmYXWw4IhdLMIxIJF6jwPkrda9fSmTg0SyRSijkgGaW1jtuP7uicicgsdC6uXMKKk4DXEPmDVG+zLzXiCxmZelPrEubKng+tTxdghPi0h4ypipN7hq7W6wh2Pldz/8valwGC26cNC5aDgGyWlt2S7mCVY6V8ud5W9tUtMIlICvDEKFa/5wdt8kqOBlJLw8HW7TxuPPPOGfP1q30sJV4PHBTvFxHg5/24T82aAgjYHFn50aqDz7x3mnPagoPyoSKt2agRqzfCmukUFba5ZxUwhh6l5tCgYupbOZN+HKU9F7NUwqfoYbHKZhlDkc+r0iBUWIo1hY4FSTBAtJhVdNeZf1h9x0q17SN+IAgAAAABJRU5ErkJggg=='); }
.profit-block-item-wrapper:nth-child(2) span::before {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAq1BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////Nr6iZAAAAOHRSTlMAy/cyaSoJBgIU7vlAvIYN0B2TJeJTGd7Hppvy2NSLhYJfOy4ifHFbNufCsaBGEKx4ZUsnt3RtlUI/MaYAAAIVSURBVEjH3ZXZmqIwEIULcCFGmk1QFhVBWWyk3drJ+z/ZEFAyCgJzMRfT5yqfn3+qzqmEwM/XeHqcjv8GEC/HJVnuLmJvwtxIPCEESxu1XyX5kCak1MTyxR4mhLVLHuKlQzeify/4B4C5nTACQGa7ic9JVWKuTUc0Os2K0DtgdLBnFTD5Mugfh0cOk/kvCtclBuslrkzsByoAoOjqFC0qXlDPDp2YCeJ6gUh7YlX5xQDVfNsVMUmLPnRvSZj4VK+V2X4r9542iFbd7AkTVo41QpYBYm1G6DEpxmnR6Fh4PkCGnmd0XuVzlg3LpodxHK/mJBcLTwbR107Psd14JdwCoCwHLuEjCRaeHip49YwM8s0+o2LOseX8acITxPuIP14Quu/My/JloFXRlkOUz2n+SzNCpC2IIiDji3ov6iIQhZWLSRuyNfQ88NsCE7wI86XKjK3HTYgKU04yZLr1YpWbyAxqohVB4M/ITIsBZF0G8Jkt2lgT4kZyTC8YF9JDqdLzwNTshSS2VwzRsVUwbRp2VxWaUrlYCjBUSA+EicsR7gVZ/1OE2f8/kBvuRl7uy8npRnbPiW0/3A4kSePXT+XZmrQgjrRBUJM6kJw3CM/thtD8ifUU3ITMtfeP4GiqzWtIYhkIWpSV954hvHRS24DS0p5niLIb9nn6grV7vy+JzR6WrgfWugoQSNcIQW+ZvgnmWYcfr9+bFbK+j7bgTwAAAABJRU5ErkJggg==');}
.profit-block-item-wrapper:nth-child(3) span::before {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAA21BMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////g4OD9/f37+/v09PTi4uLx8fHt7e3p6enn5+f39/fk5ORy/GWlAAAAPXRSTlMA/AV7974R9M0eyhcD0LqfWQmlU0Dv5darjopoY0YC6dm1lIt2XjkyJpGFfUwqG8KVgA4MC+DdIt/El2xr1NeA8gAAAf9JREFUSMftlOlyokAUhbsRRdkUFVEW932LRpPMdlFx1Hn/J5ruAloEYvyd8rPKKo983EtxAD35hjQfTRnV9SxDIF9RZrPFpwbXwKkIpTsKpMLfUcx0RRjkQri40ktXsKFmfdRKUvmKonZH2V0Oe2C06mXyMUAc3yqyGTFOnucd2M8XSdtomz7W45tNgXF0T8d/LpuT1RBh+A4mx3Zy/lj2QAXGyd0T7ciUHCJIHchKoTJv84Ig8MzYn90dHLy/t4q8hlo1MEZ5CGCKR5UzU7Z+o2o4E5Zr0jBNcyp+OuVlnJMkaTMUof6KoqyZsvOvhSm62lFVtZOtQXuEKMNBiVCZd5hCD7+c3QskwBY1nDfMUwQMjP3JdemQJEWOKAqksDscLjtIwVgQpfir212tupOeCA/A0wJoHEGWUXMCj9DTrhWrKA8ptek2VLSyP7glQIxYbBRYz4pAaPcdW8UJQ7Gq9k/wyV8VOoUf0PuUTywzpx0xAv2qdDGAWEAykldxpc6hJlkjfHhQyHiiQ36JyL9dEBQxHyCKOtQRPVMRdEVUyg6HGNsG8DYttgKdxbIQsPwoQ8uhi7WhPF5+3PZy2IK3/tzOgmBF44oOxm/HEiHtJZjBQZFy0VRrkBgHcRzOaguYrzWk2/g1807iH1MaJxlX+vaIS8SLUn8waqInT9B/8xW/bwd5nRgAAAAASUVORK5CYII=');}
.profit-block-item-wrapper:nth-child(4) span::before {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAqFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8j1z1tAAAAN3RSTlMA/fqkn/M3NC0n05eC9ubdPSIW4cebioV6HBkJzcO1ZQ0FArqScUI67Ne9f0sT2nVfVq6qa2FcTIIACQAAAhFJREFUSMftldmSqjAQhjsQQHZRQJDNDVFxHzXv/2YHOFAYCdRUzVzOdwWEn+7+03Tgj1/gJF01FWNVe0on+AaB5K1NgVQI07UnBjDMStycEXkDmZtsOaSYpArpoFzm/YoRFggDgR/1KaSYI0zQOuuJEZNe1hKzDgf1SxDPqGeVCmQA4dL1TVTIIPtxZwc37So7QXXxGcRs3g/ddGbX1oXqnjSYn2E8VCvuUmkeX2nOxwVuY+cruhPX9UI4An9rgRwV10YC27cKD7Rp0rR+7hZmG18W5BzR85V8IC1TOrOrXkdPS/XuCAlHnMWEf3dCTyiJhtoouIyikdgPXJ3azhklUUlNKP+vxS6KSnXab5WS4LYz5OLW4vdHuE1DLdHsVoXZEoLs/OXZuwRE5V6qZR6xJSqVNOG0pRUphd/ZHOSQndiMSporzSqc8LGB5zBjl3+lvImtpYvQA7YGMcbw4FqT6a1ssUVId+VHfd7g/SZKoe40TINZmGWWShmsym+qYai2bPhajJWqompKjBrHkLbqNH+D4ka1C5H2mEUc1WL0L8YAcaj/F4NsTwY5j7vj4jI8LjzGmJ07+tBQ8oHBeCC1g8Qc+lejT8DFGXsiR711OCP2RL6jahl997BY3qoYO/xyP48kVVyyz6KY6DsF304QiF5s1rGE6cHLFsAm2CbPoxzUevGZbxxno73ECfzxc/4Bz3TGWNfUHkEAAAAASUVORK5CYII=');}





@media screen and (max-width: 1199px) {
	.profit-block-item-wrapper {
		padding-right: 20px;
		padding-left: 70px;
	}
}





@media screen and (max-width: 991px) {
	.profit-block-item-wrapper {
		padding-right: 10px;
		padding-left: 50px;
	}

	.profit-block-item span::before {
		top: 3px;
	}
}





@media screen and (max-width: 767px) {

	.profit-block-stripe { padding: 15px;	}

	.profit-block-item-wrapper {
		padding: 60px 15px 10px;
		border: none;
		flex-basis: 48%;
		width: 48%;
	}

	.profit-block-item-wrapper:last-child {	border: none;	}

	.profit-block-item { margin: 0;	}

	.profit-block-item span {
		text-align: center;
		width: 100%;
		display: block;
	}

	.profit-block-item span::before {
		top: -50px;
		left: calc(50% - 25px);
	}
}
.adver-block__banners {
	padding: 15px 15px 15px 0;
	flex-basis: 33%;
	width: 33%;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.adver-block__banner,
.adver-block__banner img {
	display: block;
	width: 100%;
	height: auto;
}

.adver-block__banner {
	border: 2px solid #d8d8d8;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 3px 8px -2px #e2e2e2;
	transition: box-shadow .3s;
}

.adver-block__banner:hover {
	box-shadow: 0 3px 8px -2px #555554;
	transition: box-shadow .3s;
}





@media (width <= 767px) {
	.adver-block__banners {
		flex-basis: 100%;
		width: 100%;
		padding: 15px;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.adver-block__banner {
		flex-basis: 32%;
		width: 32%;
		min-width: 200px;
		margin-bottom: 10px;
	}
}

/* variables */
:root {
	--testVar: #c6546e;
}

/* properties */
:root {
	--testSet {
		opacity: 1;
		transition: opacity .3s;
	};
}
.novelty-block {
	padding: 15px;
}

.block-heading {
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	display: inline-block;
	vertical-align: sub;
}

.block-heading-link {
	color: white;
	font-family: "Open Sans";
	font-size: 11px;
	font-weight: 700;
	text-transform: uppercase;
	display: inline-block;
	background-color: #00bbd3;
	border-radius: 2px;
	padding: 1px 5px;
	vertical-align: baseline;
	margin: 10px;
	text-decoration: none;
	opacity: 1;
	transition: opacity .3s;
}

.block-heading-link:hover {
	color: #FFF;
	opacity: 0.8;
	text-decoration: none;
}

.novelty-photo-set {
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
}

.novelty-photo {
	flex-basis: 160px;
	width: 160px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	opacity: 1;
	transition: opacity .3s;
}

.novelty-photo:hover {
	opacity: .7;
}

.novelty-label {
	position: relative;
}

.novelty-label::before {
	position: absolute;
	top: 0;
	right: 0;
	width: 71px;
	height: 71px;
	display: block;
	content: "";
	pointer-events: none;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEcAAABHCAMAAABibqotAAAAPFBMVEUAAADqZWXqZWXqZWX////+9/fuiorrcnLyqan3zs775+f87+/639/1vLzvlZXtfn7zs7P51tbxn5/2xcWuJQWvAAAAA3RSTlMAgH8BTzA4AAABn0lEQVRYw9XWzXLDIAwEYFpWAoH5cfP+71ojdybTyaldDq0PueUbVmwih7f4+ryHnz//AUp7IK26A1KBkdGeDAk5c9Izcgai7Iw8lIqoCjIT7YAtTJwhoNTWtzMuJvVOz+hiGgDJDOSMAdYqGOhYp/FLSwWDmNF1CIGuo31gUoXsOOLtNKrZw507YlRmRp7LUK8PISAVebSKizCAgiqAshjphYFib30lQ4+pyOCabRApya+NgBajKjUXTOpHW5BXh7BaMDMB+YSP0/8CJDEQ5sThjJLRgPzFUNBs/WZGtpqp68+AnRUANaPY4cRZ1qkYyKrl4eH4le0Mt7JfGGpGzvAr2/Qvr+yU96zsgkyvbGdKIqJ9Y8agZuQMPnIBHsSMnCkVqLMZ0yMPdUinX9itpARxg4NSzDidGI1r9umFVgMgVDRcq/EAqsGoaHNWoHR/K2GgUWBF1N+2OGisHblaSUJ3k+rz+sPvoQca6TiUBIl17pVtkXYcUt65o704PBQ2QWETFDZBYRMUNkFhExQ2QWETFDZBYRMUNkFhExQ2QZ84UBbmQclX5gAAAABJRU5ErkJggg==');
}

.novelty-photo img {
	width: 100%;
	height: auto;
}

.novelty-photo-top a {
	color: #007da6;
	font-size: 16px;
	text-decoration: underline;
}

.novelty-photo-bottom a {
	color: #ff2a4f;
	font-size: 16px;
	font-weight: 700;
	text-decoration: none;
}




@media (width <= 1199px) {
	.novelty-photo {
		flex-basis: 15%;
		width: 15%;
	}
}





@media screen and (max-width: 991px) {

	.novelty-block {
		text-align: center;
	}

	.novelty-photo-set {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.novelty-photo {
		flex-basis: 32%;
		width: 32%;
		max-width: 160px;
		margin: 0 15px 15px;
	}
}





@media screen and (max-width: 479px) {
	.novelty-photo {
		flex-basis: calc(48% - 30px);
		width: calc(48% - 30px);
	}
}
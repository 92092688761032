.adver-block__slider {
	padding: 15px;
	flex-basis: 67%;
	width: 67%;
	border-radius: 20px;
}

.carousel-control {
	background-image: none !important;
}

.carousel-control-button {
	position: absolute;
	display: block;
	top: calc(50% - 9px);
	width: 18px;
	height: 11px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAALCAMAAABFyS02AAAAS1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////+DmQsHAAAAGHRSTlMAwRDrrSMF5d/Z0su4pZ2Sint6OTQtGgmqx/Q8AAAAYklEQVQI103JRw7AIBQDUX8C6b3O/U8aFITC7Pys3Q4VHbarojp/OePUBs2V5Wpgk1ZoQ5LQwqrYAl34pIMlnTP0t3T3MCtVTzA4N8BUK5sHM/BRcs8IMD4qcgbmJJXmfZYXXh0FWetjDvEAAAAASUVORK5CYII=');
}

.left .carousel-control-button {
	left: 20px;
	transform: rotateX(0) translateY(0) rotateZ(90deg);
	transition: all .3s;
}

.right .carousel-control-button {
	right: 20px;
	transform: rotateX(0) translateY(0) rotateZ(-90deg);
	transition: all .3s;
}

.left:hover .carousel-control-button {
	left: 10px;
}

.right:hover .carousel-control-button {
	right: 10px;
}





@media (width <= 767px) {
	.adver-block__slider {
		flex-basis: 100%;
		width: 100%;
		padding-bottom: 0;
	}
}
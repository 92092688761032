.site-footer {
	background-color: #f6f6f6;
	padding: 15px;
}

.site-footer-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.site-footer--left {
	flex-basis: calc(100% - 480px);
	width: calc(100% - 480px);
}

.site-footer--left-wrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-bottom: 30px;
}

.site-footer--columns {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.site-footer--column {
	margin-right: 30px;
}

.site-footer--column-heading,
.site-footer--right-heading {
	color: #5c9930;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 700;
}

.site-footer--column a {
	display: block;
	color: black;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
}

.site-footer--columns:last-child {
	align-items: flex-end;
}

.site-footer--logo {
	display: block;
	margin-right: 20px;
}

.phone-block {
	background-color: white;
	padding: 15px 15px 15px 90px;
	border-radius: 2px;
	box-shadow: 1.5px 2.6px 7px rgba(0, 0, 0, 0.14);
	position: relative;
}

.phone-block::before {
	position: absolute;
	display: block;
	content: "";
	top: 13px;
	left: 15px;
	width: 60px;
	height: 60px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAAAgVBMVEUAAACL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0FmL0Fm6il6eAAAAKnRSTlMA+ysG46/MXk+BDKn1SzN1GhDtOui6fJnC1j4l8RXSlVlDoIndtWofyI66pMMvAAACoklEQVRIx93WWc+iMBSA4ZYiS1kKlLK17Jue//8DR/1mEpHFyCRzMe8FF+rDORGbiP67CA2ktt1c0mMb8ql2Lps5nd1IcmAD09Ex7ISja8/3h5dmhrFeGZtVQ4T12t+bTTyBlbAbbzNr6nJ3YOXe0n2ExybYuzf1+wGLAm3nX0CfKNqvuLj6hLZrM8jaffpczWVoO8uAi7986OFikZDF2N625B3Pjd2z9oXTBT6cLBPDxbFo6AlMpxywCyCKFf68ttZh1+kzrBL6PS4EXNvAjqELT0yuDTvQOhfXK7yVt8BEKyRPI6ga8j2+J2sFj1/zGtPZ54sKli8xnQbQkwCtMJFNfxHZosrFCxwmsbpJtMLE7ysXVi0x9dIbR2us1TG4eSaejePPxVBLjEru0zUOmY7z2myLl3jL8jesMaaRFeYC671GPpwqYg65SVfY1N2x+PioiCVGaz2ZueoWfD7PQVuU7+c5QTaOknAHHzTfIsXO4la4g3kOU57G9w98wAH3rHVNIiLQJ3qISdvl+kaxC6D3Em1j8sAabTLYKcoSiV5wWDScEG624RPjizcZANXFee/+Spq0JXrFfjfUskzV6D/XxteuAhDNHARl8F5JEVpg7sSdDDosHti7glKAR4+gnZa4tJhHqWc35WPtKwAop71bIj1OP2FEKHlefn9hENf+86YsS/1D/F77OGs/RHa4asg3eGZOoqFnmgO5+RUmsxagPxh/xOv+AbbLs5gpVcuz2Bxw5p3F9/eirg3PYWLmOBK2uVXD6TFGc5JjrGJ9o2E0w2OM5DQOyt0KY2MqjzEqi6mv01V1KhRUzFridZt/y6VmdRFUzhVXd/x1hNc6jtQpfM/vBwA4iZFm5+cxksxwMw+dbDZrJtHZwjkgf4HLHfwLnL9XHAplF1UAAAAASUVORK5CYII=');
}

.phone-block p {
	color: black;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
}

.phone-block a {
	color: black;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	margin-right: 15px;
	text-decoration: none;
}

.site-footer--copyright {
	flex-basis: 100%;
	width: 100%;
	margin-top: 25px;
	color: black;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 400;
}

.site-footer--right {
	flex-basis: 450px;
	width: 450px;
}

.site-footer--social {
	margin: 10px 0 0;
}

.site-footer--right-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}




@media (width <= 1199px) {

	.site-footer--right {
		flex-basis: 420px;
		width: 420px;
	}

	.site-footer--columns:last-child {
		flex-basis: 150px;
		width: 150px;
	}

	.phone-block {
		width: 250px;
		padding: 15px;
		order: -1;	
	}

	.phone-block::before {
		display: none;
	}
	.phone-block p {
		font-size: 14px;
	}

	.phone-block a {
		display: block;
	}
}





@media (width <= 991px) {

	.site-footer--left {
		flex-basis: calc(100% - 210px);
		width: calc(100% - 210px);
	}
	.site-footer--right {
		flex-basis: 210px;
		width: 210px;
	}

	.site-footer--columns {
		width: 100%;
		flex-basis: 100%;
		justify-content: flex-start;
	}

	.site-footer--column {
		margin-right: 60px;
	}

	.site-footer--logo {
		width: 100%;
		flex-basis: 100%;
		margin-top: 40px;
	}

	.phone-block {
		width: 210px;
		text-align: center;
	}

	.phone-block a {
		margin-right: 0;
	}
}





@media (width <= 768px) {
	.site-footer--logo {
		display: none;
	}

	.site-footer--social:first-child {
		margin-bottom: 20px;
	}

	.phone-block {
		margin-top: -20px;
	}

}





@media (width <= 479px) {

	.site-footer--left {
		flex-basis: 100%;
		width: 100%;
	}
	.site-footer--right {
		display: none;
	}

	.site-footer--left-wrapper:last-child {
		margin-bottom: 0;
	}

	.site-footer--column,
	.site-footer--columns:last-child {
		flex-basis: 100%;
		width: 100%;
		margin-right: 0;
		text-align: center;
	}

	.phone-block {
		width: 100%;
	}

	.site-footer--copyright {
		text-align: center;
	}
}
.breadcrumb {
	padding: 8px 15px;
	margin: 20px 0;
	list-style: none;
	background-color: #f5f5f5;
	border-radius: 4px;
}

.breadcrumb a:not(:last-child) {
	margin-right: 25px;
	position: relative;
	font-size: 15px;
}

.breadcrumb a:not(:last-child)::after {
	content: "»";
	position: absolute;
	right: -15px;
}





@media (width <= 767px) {
	.breadcrumb {
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 0;
	}
}
.advantage {
	flex-basis: 330px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.advantage h2 {
	margin-top: 0;
}

.advantage figure {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	background-color: white;
	box-shadow: 0 0 4px rgba(229, 229, 229, 0.75);
	margin-top: 15px;
	transition: box-shadow .3s;
}

.advantage figure:hover {
	box-shadow: 0 0 4px rgba(145, 210, 86, 0.75);
}

.advantage figure span {
	display: block;
	width: 50px;
	min-width: 50px;
	height: 50px;
	margin-right: 10px;
	flex-basis: 50px;
}

.advantage figure:nth-of-type(1) span {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAclBMVEUAAAChyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVmhyVk4PpEpAAAAJXRSTlMAqplLPLSHBlX68ui84d23+NZ3XBUEkIBqRBwMz8iyo3PDYjAlIN5NAgAAASlJREFUSMft08luwyAUhWHAcTA4nuchsZPmvP8rlrqq5eHSqllk5W+DhPRjgYEdDm+Ul4KUFtakgsXJmpzhcwLg/JK41PRLiR8ayWXsltPO2bhakpm/2JRUmNzo5OwZtdCIF011+RI17FubSWIvbYLQet4RXEls/6mQ2ZIapiFOjINP44PvadMU+6RGMI0atI994kBNoyf2fPorJ4BZpEAk2b+SYC7sycgnP3+qSefClkhQf9+ezEfQw/07oW7y6aLDSm6S5q6TsbAkjYLB14kzTQoqCYQIkHglMIgZEPm4emYcbtukml96T12FAIZaJ63gnMeoWWdW3Tx/j+Uad86pmzxARQHifDXpInZ96JyRuh6A2iyWJwBih1nIrEyfu4UeZdqyw+E9PgFG1iku72niwwAAAABJRU5ErkJggg=='); }
.advantage figure:nth-of-type(2) span {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAjVBMVEUAAACiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyViiyVgdfDoxAAAALnRSTlMAhvCX3LT60ndL9TsNzMjD7AWsf1VspqFmGC7XkFriRDOciXMeXygjEbhR5r15WhB5QAAAAs5JREFUSMft1dnSmjAABeAQQkjCIvu+igqoPe//eA1tbfuj/r1rb3pm9CKTD0hyBsj//O2E5SDbVMRHnVikZzmU4SeznTiqOQDKPM+73/UfCwDw2jL6V+40UtjjJKvy4/hSycn0ucoue3Gh/nkhb+Mmd+XsxmLq/mGRrNmNGOim8j1YihrmnjCDwmvay/M658TQa2n8J2ITB7kNcHbPmlgUOiJu8jvjAMt5S6yXxCWYWuZFTDFGt18X+bf2jBOhL8iVF9+IJAdj88S0yDZL2KTayLmgVrUzqaLow9dkdpTiFoPXfzSl6KBgTfVYHn+SsunSDBzMmAmpcuwMMelkWNtywYNtLTcOHbY2bfk4b2NPoMY+DMtKJkUqRFokv5fSlRnHE1lTBrUaSbVrwjK0x7uCd66fiEValUbQCeqDlZk6meXXFDr5BIfYO+JGK2kpOWG4sOiL10XRjUVR4IvVnq8griYf73LJOEcjNlIRWzw2ebuwcSByI4nJf29zRQ+Je2XgqXxNKgHU/cfyL1vDr1kAeGOQyeheLuu4nOpcWl1eQw/34a78QTx/f8IkjuwAv8I7L8p9/qr8AWqzGNzHa2MenL53hvk0nBsb9E35TR9A4Fu5br9OfBx1kwHYjfqk/KlkXl4rz6aq9ijLD901eVN+ydP+0/Knai13pqAKifuaXHrF1ciCy/7RuA+Oe8zyuXmQ8dR0XyKA+6IkbmDsCbz0+thfSjmnFDrKy2Lpbkfm44kwCzRKHTc8OTI5F4XuvnNZvrXPSSMKiz2RA2mxUqA75McvRZJcr0lSfDnqTQPoipYcXhAJsiCeAuYHnEKH8sBnwRRjIZDPJKW9/F5xW2yeZBnZZokfJyZ7mu5IaKLD/I7M6GA+v0mHMcANZsLi30nMElMPB2P15qs05T4FuFKef7v5nlIcoH4+OSH5LOUgz0IYx6MhxPblI//zD/IV765Q/XMpln4AAAAASUVORK5CYII='); }
.advantage figure:nth-of-type(3) span {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAbFBMVEUAAACgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFljyHxRAAAAI3RSTlMAd4mHeAXrq6TX8eFrVTYgjmNQPvv321pHLMgI5sNv7ZSTE7u3QY0AAAE4SURBVEjH7ZPZTsMwEEUdN7azN3s3KMv9/3/ESyTHcuiAQKBKPS9RbnQUz4yHPVjIZENkEcBuI0vvTXlX07NRBgSYLB2Q1yxGAWcmcT1ihcleUOlsPMXKZOKmumSB4bMkVqB56o6B4bPNgkDwj8qZf45XiCl6flWBgbEU8K9/qHA+fFfx/FzZRNRZXoxlVouvKv2MhbnfUhIDY4152HoFB1CotlUFAC6oWjTakJWwciW1Qys1kO/Zwj4HekoRJaQ2Gpk5R6IUhHIAOv1ppzNLBxyI8hWkWCtCQhFNnqGYVzQKeaDwSCnQhkqLgnlyBNifTuBOsWSm59N6ymOslO5gV1ikOVjJVpwST1j+pbJB48q/iWuywzf5Nm6UFj9KgujC1IzkNbyWb6QQXX5aiFaMxi/yZBf5geMDHG5K0Hb/KWcAAAAASUVORK5CYII='); }
.advantage figure:nth-of-type(4) span {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAeFBMVEUAAACgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFmgyFlFEl4DAAAAJ3RSTlMA+AXyp3CF7MNPWOHQl0orrX5gEQ3aXicJvBXVOB16ajMgi59C27bAZb+YAAABzklEQVRIx+1V2baCMAxkXxQBF0Bkdbv5/z+8SWzVUhDP4dV50J60Q7ZpavywGKdtdxDLQ7fdf0MJAHw+ud/6AMEXjBAItld4Nq/CWYZVgYrKmqPs8NTaFMfNNf7s5pzQofp0sSm4y6mmD3xwcw5bJ8MzKadecglSAMicNjyPEXIfBJI3ayJj9HOdYkvGRjFvpNmeomSpa6nJuWk2RYnQeX8cTfLYY9CRbncAYFCbzjkIV5iRo1M8pBT4H0fgt2xpTLgZjAL3vPEexihKLtGKM3+2JB7vqItmlyTmNw5X7WzCTtkbYgWInP4jw+PAr3CXueXseahfiqe03iiFCfmz0iUJTtV0TIzgyN4q40IUBxevMgfEiYeyCLiiNZjXNZYn4Uiafi/uJ3MSNXWzeaz/SLwNOokMK0WzSLuhr7q6F0bc7g7kJDQ6JAMUihc9F4mUBHLFYt9hpeSiV0yg57ZSfyKkyIpN9EUmFBgvit4XvcM1kLo4sAxWYm9SY4wSysdXzAogERqbVjKpC6DmBpaYgSeV/PG+WBu5n9wa7b4supXzd3/5hJFzbCiL4jXHZqelF3jatFw0k/XJb8v4efIveF8WvGKL30r9RT4ZP6j4BxA8TUwCYwxCAAAAAElFTkSuQmCC'); }
.advantage figure:nth-of-type(5) span {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAMAAAAp4XiDAAAAe1BMVEUAAACfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFqfyFr8QcijAAAAKHRSTlMA+gTzDplp36ZgNgmvekLr0HNKGRPlybaFUCKgKtbCvYA+ii+TVpBbzKQi+gAAAcFJREFUSMft1cm6ojAQBeBiBpmRSUXBAT3v/4SNgFa4hIZe9O7+Wz+TnJyK0q8fVPpHrofM8wOdtnMwOJ/M3cb9nmBK/nwdaVWBH8KiSfb0N54CCcuOr+7yhe3M0xkSSmTcaZke+F6GGY1PKLc/3GoNE8mWXivHiDjeXbJwXR7mm7u72Lb6OJIrOADg9tWgzIuKBmnSFDnNlcC3fd/TgFCJVo5eQ6Bc/J1qYKV/jcszDvpw1LxJUlpUjddfmN+ldWWh/THsu/XoFqh8V8lD4/ZNEh3NPqztCFd8j+uxkaIbIguIhfVLawjLy6evkzAw7XvDEFf+StOH5RWuZS7MlQIc+1BikTb06QkHYzAbIXUCiA3FSD5hv2PPwRxo7xP7MCZ3+xTDYhpsD0C7GCEcEmTWJyy3yCLZGJ/wwS2y2/iRSoKWw9JcgJ5NolThFudcra6ch6/TRM67ShSyp9VgRzJcwswVTXe81rBlm6UlHMkUaxcyFYA3mwxPlkp/JNMYnUY63jpJmGgrdHIa6cmDx1vqCIMyQDH4pfAULDhb9DLadHgp/RRU6/8r1RB2mAKX1iV48FveRj1z2K10pwv763/4A1IVTSmlVeWuAAAAAElFTkSuQmCC'); }

.advantage figure figcaption a {
	display: block;
	text-align: center;
	color: #25262a;
	font-family: "Open Sans";
	font-size: 20px;
	font-weight: 400;
}

.advantage figure figcaption a:last-child:not(:only-child) {
	text-align: center;
	display: block;
	color: #00bbd3;
	font-size: 18px;
	text-decoration: underline;
}

.advantage figure figcaption a:hover {
	text-decoration: none;
}

.advantage figure span + figcaption p {
	text-align: left;
}

.advantage figure span + figcaption a {
	text-align: left;
}




@media (width <= 991px) {

	.advantage {
		flex-basis: 100%;	
		flex-direction: row;
		flex-wrap: wrap;
		/* justify-content: space-around; */
	}

	.advantage h2 {
		margin-top: 40px;
		flex-basis: 100%;
	}

	.advantage figure {
		flex-basis: 48%;
	}
}





@media (width <= 767px) {
	.advantage {
		order: 2;
		margin-bottom: 20px;
		justify-content: center;
	}

	.advantage figure {
		flex-basis: 100%;
		max-width: 400px;
	}
}
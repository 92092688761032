.remodal-overlay {
	background: rgba(0, 0, 0, 0.3);
}

.remodal {
	padding: 0 0 20px;
}

.popup-cart-heading {
	color: #1f2126;
	font-family: "Open Sans";
	font-size: 24px;
	font-weight: 400;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right: 15px;
	border-bottom: 1px solid #e5e5e5;
}

.popup-cart-main-wrapper {
	padding: 10px 20px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e5e5e5;
}

.popup-product-name-and-size {
	max-width: 180px;
	text-align: left;
}

.popup-product-name {
	display: block;
	color: #008393;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
	text-decoration: underline;
	margin-bottom: 10px;
}

.popup-product-size {
	display: block;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 600;
	color: #908a8a;
}

.popup-product-size b {
	color: #25262a;
}

.popup-product-price {
	font-weight: 600;
	font-family: "Open Sans";
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	text-align: center;
}

.popup-product-price .product-old-price {
	color: #8c7d7d;
	text-decoration: line-through;
	font-size: 18px;
}

.popup-product-price .product-new-price {
	color: #25262a;
	font-size: 24px;
}

.product-remove {
	display: block;
	margin-top: 3px;
	width: 20px;
	height: 20px;
	cursor: pointer;
	transform: rotate(0deg);
	transition: transform .3s;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAMAAAC6V+0/AAAAUVBMVEUAAAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAD/AAAgx90pAAAAG3RSTlMABroPXhyAGFCup1TAtLKqkoYenmJYH2EUC6AteLa/AAAAmElEQVQY022RWRbDIAhFcYxDo0mMGbr/hfaA1dik7wPw4gFEIDGfnLPx7Rk0Gb4K9KcMprJZtwtMzyWYJHSSE1qv4Uc6A7AX6xGCE/KOkRjQDgcVkOAEHfkCoDilRASLHsGmMIEaCRZKrMBwwa3BsbJFVRpaI9U3yus10iHKSI/hCzD3Z/r/C3mu7r5ksQfTFTcpOmvT9zs+PCUFBi4ArPAAAAAASUVORK5CYII=');
}

.product-remove:hover {
	transform: rotate(360deg);
}

.popup-cart-proceed-wrapper {
	padding: 20px 20px 0;
}

.popup-product-total-price {
	display: block;
	text-align: right;
	font-family: "Open Sans";
	font-size: 20px;
	color: #908a8a;
	font-weight: 700;
	margin-bottom: 20px;
}

.popup-product-price b {
	color: #1f2126;
	font-weight: 600;
	margin-left: 10px;
}

.popup-cart-proceed-buttons {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

.popup-cart-proceed-buttons a {
	display: block;
	width: 210px;
	text-decoration: none;
	position: relative;
	padding-top: 10px;
	padding-bottom: 10px;
}

.popup-cart-proceed-buttons a:active {
	top: 1px;
}

.popup-cart-proceed-buttons .popup-cart-order-btn {
	background-color: #b6d53a;
	border: 1px solid #b6d53a;
	color: white;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	transition: color .3s, background-color .3s;
}

.popup-cart-proceed-buttons .popup-cart-order-btn:hover {
	background-color: white;
	color: #b6d53a;
}

.popup-cart-proceed-buttons .popup-cart-continue-btn {
	color: #908a8a;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 400;
	background-color: #FFF;
	border: 1px solid #a0a0a0;
	transition: color .3s, border-color .3s;
}

.popup-cart-proceed-buttons .popup-cart-continue-btn:hover {
	color: #b6d53a;
	border-color: #b6d53a;
}






@media (width <= 641px) {
	.popup-cart-main-wrapper {
		flex-wrap: wrap;
		justify-content: space-around;
	}

	.popup-product-image {
		flex-basis: 28%;
	}
	.popup-product-name-and-size {
		flex-basis: 38%;
		text-align: center;
	}
	.product-qty {
		flex-basis: 28%;
		margin-top: 10px;
	}

	.popup-product-price {
		flex-basis: calc(100% - 30px);
		margin-top: 10px;
	}

	.popup-product-price {
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.popup-product-price span {
		margin: 0 10px;
	}

	.popup-product-total-price {
		text-align: center;
	}

	.popup-cart-proceed-buttons {
		justify-content: space-around;
	}

	.popup-cart-proceed-buttons a:first-child {
		margin-bottom: 10px;
	}
}
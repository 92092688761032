.product-wrapper {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	padding-bottom: 50px;
	flex-wrap: wrap;
}

.product-item {
	flex-basis: 24%;
	max-width: 280px;
	min-width: 278px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: none;
	border: 1px solid  #e5e5e5;
	transition: box-shadow .3s;
	margin-bottom: 10px;
}

.product-item:hover {
	box-shadow: 0 0 10px rgba(0, 187, 211, 0.75);
}

.product-item__top figure {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
}

.product-item__top figure > a {
	width: 100%;
	overflow: hidden;
}

.product-item__top figure img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.product-item__top figcaption {
	text-align: center;
	padding: 15px 5px;
}

.product-item__top figcaption a {
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
	line-height: 18px;
	text-decoration: underline;
}

.product-item__top figcaption a:hover {
	text-decoration: none;
}

.product-item__top .product-label--new,
.product-item__top .product-label--sale {
	position: relative;
}

.product-item__top .product-label--new::after,
.product-item__top .product-label--sale::after {
	position: absolute;
	top: 10px;
	left: 0;
	color: white;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	padding: 5px;
	text-transform: uppercase;
}

.product-item__top .product-label--new::after {
	background-color: #fd474f;
	content: "Новинка";
}

.product-item__top .product-label--sale::after {
	background-color: #86d20e;
	content: "Распродажа";
}

.product-item__bottom {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.product-price-and-qty {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	border-bottom: 1px solid  #e5e5e5;
	min-height: 81px;
}

.product-item .product-price {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-basis: 48%;
	align-items: center;
}

.product-item span {
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
}

.product-item .product-old-price {
	color: #8c7d7d;
	text-decoration: line-through;
}

.product-item .product-new-price {
	color: #25262a;
}

.buy-now-qty {
	display: flex;
	justify-content: center;
	height: 36px;
}

.buy-now-qty .button,
.buy-now-qty .qty {
	width: 36px;
	height: 36px;
	font-family: Roboto;
	font-size: 18px;
	line-height: 36px;
	text-align: center;
	font-weight: 300;
	color: #1f2126;
}

.buy-now-qty .button {
	background-color: white;
	border: 1px solid  #e5e5e5;
	cursor: pointer;
	user-select: none;
	transition: border-color .3s, background-color .3s;
}

.buy-now-qty .button:hover {
	border: 1px solid #00bbd3;
}

.buy-now-qty .button:active {
	background-color: #e5e5e5;
}

.buy-now-qty .qty {
	background-color: white;
	pointer-events: none;
	border: 1px solid #e5e5e5;
	border-width: 1px 0;
}

.product-item .product-size-and-buy {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
}

.product-item .product-size {
	flex-basis: 48%;
}

.product-item .product-size	select {
	margin-left: 0;
}

.product-item .product-buy {
	flex-basis: 48%;
	text-align: center;
	border: 1px solid #FD9401;
	border-radius: 3px;
	background-color: #FD9401;
	transition: background-color .3s;
	position: relative;
}

.product-item .product-buy a {
	display: block;
	height: 30px;
	color: white;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	line-height: 30px;
	text-decoration: none;
	transition: color .3s;
}

.product-item .product-buy:hover {
	background-color: #FFF;
}

.product-item .product-buy:hover a {
	color: #FD9401;
}

.product-item .product-buy:active {
	top: 1px;
}




@media (width <= 1199px) {
	.product-wrapper  {
		justify-content: space-around;
	}
}

.item-info {
	margin-top: 40px;
	flex-basis: 100%;
}

.item-info .nav-tabs li a,
.item-info .nav-tabs li.active a {
	text-decoration: none;
	color: #25262a;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	margin-right: 0;
	border-radius: 0;
	border: 1px solid #e5e5e5;
}

.item-info .nav-tabs li:not(:last-child) a {
	border-right: none;
}

.item-info .nav-tabs li.active a {
	font-weight: bold;
}

.item-info .tab-content {
	padding: 20px 0;
}

.item-info .tab-content p {
	color: #25262a;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
}




@media (width <= 767px) {
	.nav-tabs {
		border-bottom: none;
	}

	.item-info .nav-tabs li:not(:last-child) a {
		border: 1px solid #e5e5e5;
		margin-right: -1px;
	}
}
.latest-block-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.latest-block--news,
.latest-block--blog,
.latest-block--about-us {
	flex-basis: 32%;
	width: 32%;
	flex-grow: 1;
}

.latest-block--news {
	margin-right: 15px;
}

.latest-block__instance {
	width: 100%;
	border-bottom: 1px solid #8ed157;
	padding: 15px 0;
	font-family: "Open Sans";
}

.latest-block__heading {
	color: #6aaf38;
	font-size: 20px;
	font-weight: 700;
}

.latest-block__excerpt {
	color: #1c1c1c;
	font-size: 16px;
	font-weight: 400;
}

.latest-block__date {
	color: #7c7c7c;
	font-size: 14px;
	font-weight: 400;
}

.latest-block--blog {
	margin-right: 15px;
}

.comments__instance {
	padding: 15px 0;
	font-family: "Open Sans";
}

.comments__body {
	border-radius: 20px;
	background-color: #f4f4f6;
	padding: 10px;
	text-align: center;
	position: relative;
}

.comments__body::after {
	position: absolute;
	bottom: -19px;
	right: 125px;
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 24px 24px 0;
	border-color: transparent #f4f4f6 transparent transparent;
}

.comments__excerpt {
	font-size: 16px;
	color: black;
	margin: 5px 0;
}

.comments__link {
	color: #19aac5;
	margin-left: 15px;
	text-decoration: underline;
}

.comments__link:hover {
	color: #19aac5;
	text-decoration: none;
}

.comments__date-time {
	color: gray;
	font-size: 12px;
	display: block;
	text-align: right;
	margin-top: 15px;
}

.comments__name {
	font-size: 16px;
	color: black;
	display: block;
	text-align: right;
}





@media screen and (max-width: 1199px) {
	.latest-block--news,
	.latest-block--blog {
		flex-basis: 48%;
		width: 48%;
		flex-grow: 1;
	}


	.latest-block--about-us {
		flex-basis: 100%;
		width: 100%;
		flex-grow: 1;
	}
}





@media screen and (max-width: 767px) {
	.latest-block--news,
.latest-block--blog,
.latest-block--about-us {
	margin: 15px;
}
}
.item-card-photo {
	flex-basis: 400px;
	display: flex;
	flex-direction: column;
}

.item-card-photo--top .item,
.item-card-photo--bottom .carousel-ind-inst {
	width: 100%;
	height: auto;
	overflow: hidden;
}

.item-card-photo--top img,
.item-card-photo--bottom img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.item-card-photo--bottom {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
}

.carousel-ind-inst {
	cursor: pointer;
	will-change: opacity;
	opacity: 1;
	transition: opacity .3s;
}

.carousel-ind-inst:not(:last-child) {
	margin-right: 15px;
}

.carousel-ind-inst:hover {
	opacity: 0.7;	
}
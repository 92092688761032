.bottom-menu {
	background-color: #00bbd3;
	margin-top: 15px;
	border-radius: 10px;
}

.bottom-menu .navbar-nav {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}

.bottom-menu .navbar-nav li {
	flex-grow: 1;
	text-align: center;
}

.bottom-menu .navbar-nav li a {
	color: white;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	padding: 15px 10px;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus,
.nav .active > a,
.nav .active > a:hover,
.nav .active > a:focus,
.nav > li > a:hover,
.nav > li > a:focus {
	background-color: inherit;
	border-color: inherit;
	text-decoration: underline;
}

.dropdown-menu {
	min-width: 360px;
	left: calc(50% - 180px);
}

.bottom-menu .open > .dropdown-menu {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.bottom-menu .dropdown-menu > li {
	flex-basis: 32%;
	width: 32%;
	text-align: center;
}

.bottom-menu .dropdown-menu > li > a {
	color: black;
	background-color: #FFF;
	text-decoration: none;
	padding: 5px 10px;
	font-size: 15px;
}

.bottom-menu .dropdown-menu > li > a:hover {
	text-decoration: underline;
}

#bottom-menu-wrapper.is-sticky {
	position: fixed;
	width: 100%;
	background-color: #00bbd3;
	top: 0;
	left: 0;
	box-shadow: 1.5px 2.6px 7px rgba(0, 0, 0, 0.14);
	transition: all .3s;
	z-index: 1;
}

.home-link {
	display: none;
	position: absolute;
	top: 9px;
	left: 15px;
	height: 50px;
	width: 100px;
}

.is-sticky .home-link {
	display: block;
}

.is-sticky .home-link::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100px;
	height: 50px;
	display: block;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAyCAMAAACd646MAAAAt1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8QiqlJAAAAPHRSTlMA1vzw2iZj+sQFFQn00jD3vw/imGjt6ebevLWwokU3k04Zm8h/WM2riahwbT8MuY5zXR4ShDojep12UyG6S8ApAAAEY0lEQVRYw+3W13baQBAG4F9doF5RA0SH0ItjO573f66wK6GQyoHYFzkn34U4ArGj3ZlZCf/9E7oBPl5ptvGhvhyBHm1xZW2XZbk64N20JBVok9Sch67fkfUsyzfubPJeQbQCgENjMMoikfPQBve2G42e3imIACAmsQt4Cz//ftSlEL5PEKcFCETmWpXzn/Kg/FWU1uCzooCvVB9IiUjSf7U2imzjYbrDxnVf5yJpbQR05h9RM74YuFBdNO4tdKXcsTjcBp+IscA9FWa/hYZbojKIpTsCvF2WbNkhrtclJsKZnRBluKIu6ulZTueOICoaY5POnCqYD2CnEbmoeeVzUGykTTZcKpjp0Q6P+WxSQ0dvRHRpTHuuC6NhOFGl4GUjJLKUWXiUrVEjFtnxE872U+FlUiVaPrKcdxJK8bA9fW/KtmVBfr7k3pBZqW+LNI+iNR41pGviAcg7czQ8/xxEGQUWPDPBw2S6UsAW0uuGUKLz2aIQzqFC+oRHBfRNgG7CRjp5qIUSsBKskHUKdfCo8dWCdW2ZNV+5wEWasLkG1UYnHvGgrT1uMiIIX1gerB4unD5USqr0iKKHB4V9fBKJSQfRid2x0Eft/MvAcLQDmB5FeFTbauNp/pILAQqd94SLhjlFRktwCwrwsODSZk9xG1hJVzH6pKxohHoikoHHpbPq39MTDjNzhsaAUkhO9WOpSTb+RmFte4PBRBrnlrvCN4KGIZXoqqVaxJnRNOjQ42VZgjsMg1aVXVbov5+tHeijqZT0VQ9XnklVKMdxKlgdTUUj4ZNT61wtTFdmlfJqyq7qxX9sWGOt73+ITDqaJ9VYPKE2ox2rDy3mF8UUKnHOvk3QCxLtRitNu7jmmRH61HSMQE/gliRXuxG7fKLR5ES0gkuRgdC8ufNYK1yLnYFyVbRhXWW2KH7mm5HOlkykLVwaIiXn/K1OPm4Q1KuTdUdS0OmAMV4z3bWqVmxFNGevt+S0ga7IQrUU5EQl0BOpixuyHI1uJBvY8nvGWEoKdTIlbQDA5Td7cNi6tE2KeF3Nib9O+zTCLb34kgAll4bnozhmJ7nJ19knZ9PCjqjHTwR+FPk/XoksForfRv9GlFdzYa8NZanHrsIq1eI9FPHplERqsH/TiPVqSqICZER8xC7x0W2RLeRQxw17P/Jledrnw85Nj41gVn3eoRi9QiZnzTYbVhBh/RZ1lIjG9RVY+W3cNLCVumU/O7xtNlV7FCSuWNLpxHqUpBZ/BQnrJ2uHz44mWMt73EPIeJGlLZ57PmBbJLOFZ8mkLdq+RCbvLktjrT8k1j76EPd49sG0rEGV0rB6s1ngpE1iMgy/2LHkG9YUJAKzxKUJ0gz3OHTqznwRvkxG4kjBmWuR4DoqdFLl9JwLUV0KOZCIqiV4Kg1HGe6y2aI2zrIXuypx35gLmzfAy0cs0+2ZXnTZ5yLdsbTpe9xl8ot78uQd3pM3HOBHT0If7+p4wPfW3Zm8xMdq61bfw38f4ys954xlvw073AAAAABJRU5ErkJggg==');
}

.is-sticky .bottom-menu {
	margin-top: 0;
	border-radius: 0;
	transition: all .3s;
}

.is-sticky .navbar-nav {
	padding-left: 90px;
	padding-right: 175px;
}

.is-sticky .bottom-menu .navbar-nav li {
	margin-top: 8px;
	margin-bottom: 8px;
}

.is-sticky .bottom-menu .navbar-nav li a {
	font-size: 13px;
}

.is-sticky.middle-menu__cart {
	position: fixed;
	top: 5px;
	right: 15px;
	z-index: 10;
}





@media (width >= 1500px) {
	.is-sticky .navbar-nav {
		padding-left: 0;
		padding-right: 0;
	}

	.is-sticky .bottom-menu .navbar-nav li a {
		font-size: 15px;
	}
}






@media (width <= 1199px) {

	.bottom-menu .navbar-nav li a {
		font-size: 15px;
	}

	.dropdown-menu {
		left: calc(50% - 160px);
		min-width: 320px;
	}

	.bottom-menu .dropdown-menu > li > a {
		font-size: 13px;
	}

	.is-sticky.middle-menu__cart {
		position: static;
	}

	.is-sticky .navbar-nav {
		padding-right: 0;
	}
	

}





@media (width <= 991px) {
	.navbar-toggle {
		float: none;
		display: flex;
		align-items: baseline;
		margin-left: auto;
		margin-right: auto;
		padding: 1px 6px 1px 12px;
	}

	.bottom-menu__button {
		float: left;
		color: #fff;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 18px;
		margin-right: 15px;
	}

	.bottom-menu__button:hover {
		text-decoration: underline;
	}

	.bottom-menu__hamburger {
		float: left;
	}

	.navbar-toggle .icon-bar {
		background-color: #FFF;
		transition: all .3s;
		width: 21px;
	}

	.navbar-toggle:not(.collapsed) .icon-bar {
		transition: all .3s;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(1) {
		transform: rotate(45deg);
		transform-origin: left center 0;
		position: absolute;
		top: 4px;		
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(2) {
		opacity: 0;
	}

	.navbar-toggle:not(.collapsed) .icon-bar:nth-child(3) {
		transform: rotate(-45deg);
		transform-origin: left center 0;
		position: absolute;
		top: 15px;
	}

	.navbar-collapse {
		box-shadow: none;
	}

	.bottom-menu .navbar-nav { margin: 0; }

	.bottom-menu .navbar-nav > li {
		flex-basis: 100%;
		width: 100%;
	}

	.bottom-menu .navbar-nav li:last-child {
		margin-bottom: 15px;
	}

	.bottom-menu .navbar-nav li a {
		padding: 5px;
	}

	.is-sticky .home-link {
		display: none;
	}

	.is-sticky .navbar-nav {
		padding-left: 0;
	}
}





@media (width <= 767px) {

	.navbar-collapse {
		padding-left: 0;
		padding-right: 0;
	}

	.bottom-menu {
		margin-left: -15px;
		margin-right: -15px;
		border-radius: 0;
	}
}





@media (width <= 479px) {
	.bottom-menu .dropdown-menu > li {
		flex-basis: 48%;
		width: 48%;
	}
}
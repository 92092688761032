.card-page-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 30px;
}

.item-card {
	flex-basis: calc(100% - 380px);
	display: flex;
}

.item-card-details {
	padding: 0 40px 15px;
}

.item-card-details h1 {
	margin-top: 0;
}

.item-card-details__art,
.item-card-details__material {
	color: #25262a;
	font-family: "Open Sans";
	font-size: 16px;
}

.item-card-details__price {
	font-family: "Open Sans";
	font-weight: 600;
}

.item-card-details__price--old {
	color: #8c7d7d;
	font-size: 18px;
	text-decoration: line-through;
	margin-right: 15px;
}

.item-card-details__price--new {
	color: #25262a;
	font-size: 24px;
}

.item-card-details__presence {
	color: #8c7d7d;
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 300;
	font-style: italic;
	margin-bottom: 15px;
}

.item-card-details .product-size {
	width: 120px;
	display: flex;
	flex-direction: row-reverse;
}

.item-card-details .product-size select {
	margin-left: 0;
}

.item-card-details .item-card-details__table-size {
	display: block;
	margin: 10px 0;
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 700;
	text-decoration: underline;
}

.item-card-details__size,
.item-card-details__qty {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 15px;
}

.item-card-details__size > span,
.item-card-details__qty > span {
	color: #25262a;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	margin-right: 15px;
}

.item-card-details__table-size:hover {
	text-decoration: none;
}

.item-card-details .buy-now-qty {
	justify-content: flex-start;
	margin-top: 10px;
}

.item-card-details__buy .product-buy {
	text-align: center;
	border: 1px solid #FD9401;
	border-radius: 3px;
	background-color: #FD9401;
	transition: background-color .3s;
	position: relative;
	margin-bottom: 15px;
}

.item-card-details__buy .product-buy a {
	display: block;
	height: 40px;
	color: white;
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 600;
	line-height: 40px;
	text-decoration: none;
	transition: color .3s;
}

.item-card-details__buy .product-buy:hover {
	background-color: #FFF;
}

.item-card-details__buy .product-buy:hover a {
	color: #FD9401;
}

.item-card-details__buy .product-buy:active {
	top: 1px;
}

.item-card-details__buy .product-buy--1-click {
	border-bottom: 2px solid #d56e00;
}

.item-card-details__social {
	margin-top: 15px;
}

.item-card-details__social p {
	color: #00bbd3;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase;
}




@media (width <= 1199px) and (width >= 992px) {
	.item-card {
		flex-basis: calc(100% - 350px);	
	}
	.item-card-details {
		padding-left: 20px;
		padding-right: 0;
	}
}





@media (width <= 991px) {
	.item-card {
		flex-basis: 100%;	
	}
}





@media (width <= 767px) {
	.item-card {
		flex-wrap: wrap;
		padding-left: 15px;
		padding-right: 15px;
		justify-content: center;
	}

	.item-card-photo,
	.item-card-details {
		flex-basis: 100%;
		margin-bottom: 20px;
		max-width: 400px;
	}

	.item-card-details {
		padding-left: 0;
		padding-right: 0;
	}
}
.page-pagination {
	margin-bottom: 60px;
}

.page-pagination ul {
	padding-left: 0;
	list-style: none;
	width: 100%;
	text-align: center;
}

.page-pagination li {
	display: inline-block;
	text-align: center;
}

.page-pagination li.disabled {
	opacity: 0.7;
}

.page-pagination a {
	display: block;
	font-family: "Open Sans";
	font-size: 14px;
	font-weight: 400;
	color: #25262a;
	transition: color .3s, bacground-color .3s;
	text-decoration: none;
	width: 34px;
	height: 34px;
	line-height: 34px;
	background-color: rgba(245,245,245,.3);
}

.page-pagination li.active a,
.page-pagination li:not(.disabled):hover a {
	color: #00bbd3;
	background-color: rgba(245,245,245,1);
}

.page-pagination li.active a,
.page-pagination li.disabled a {
	cursor: default;
}

.page-pagination li.disabled {
	/* display: none; */
}